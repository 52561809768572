import jQuery from "jquery";
import slick from "slick-carousel";
import slickAnimation from './slick-animation';
import countdown from './countdown';
import csvtojson from './csvtojson';
import magnificPopup from 'magnific-popup';
import Vue from 'vue';

jQuery('.slider').slick({
  autoplay: true,
  speed: 1200,
  lazyLoad: 'progressive',
  arrows: false,
  dots: true,
}).slickAnimation();

var deadline = new Date("03/06/2023 09:00");
// countdown('clock', deadline);

jQuery(document).ready(function() {
  $('[data-youtube]').youtube_background();
});


new Vue({
  el: '#app',
  data: {
    data: [],
    speakers: [],
    investors: [],
    thesis: [],
    key: '1xjqpxED0pySb4y9zbGfhxwFlGj9isF9Vucvxfa782Zg',
    sheet: 1
  },
  computed: {
    // speakers: function() {
    //   return this.sort('speaker')
    // },
    investors: function() {
      return this.sort('curator')
    }
  },
  methods: {
    sort: function(typ) {
      var sorted = this.data.filter(function(item) {
        return item.type == typ;
      })

      // Set slice() to avoid to generate an infinite loop!
      sorted = sorted.slice().sort(function(a, b) {
        return a.order - b.order;
      });

      return sorted.filter(function(item) {
        return item.order > 0
      })
    },
    gdrive(url) {
      try {
        var id = url.match(/drive\.google\.com\/file\/d\/(.*?)\//)[1];
        return "https://drive.google.com/thumbnail?id=" + id;

      } catch (e) {
        return "https://drive.google.com/thumbnail?id=1-JTBMH4OjOtfrueHU99D0vGIJ4pq3DWu";
      }
    },
    loadData: function() {
      var vm = this;
      fetch(`https://events.startupmission.in/api/event/sk23/speakers`)
        .then(response => response.json())
        .then(function(json) {
          vm.speakers = json.speaker;
          vm.investors = json.investor;
        });
    },
    loadThesis: function() {
      var vm = this;
      fetch(`https://gsapi.futurelabkerala.in/api?id=${this.key}&sheet=3&columns=false&integers=false`)
        .then(response => response.json())
        .then(function(json) {
          vm.thesis = json.rows;
        });
    }

  },
  mounted() {
    this.loadData();
    // this.loadThesis();
    jQuery('.video-pop-up').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });
  }
})
function getTimeRemaining(endtime) {
  var t = Date.parse(endtime) - Date.parse(new Date());
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function calculatePercentage(item,value, max){
  var percent = (value / max) * 100
  item.querySelector('.circle').style.strokeDasharray = `${percent}, 100`
}

function initializeClock(id, endtime) {
  var clock = document.getElementById(id);
  var daysSpan = clock.querySelector('.days');
  var hoursSpan = clock.querySelector('.hours');
  var minutesSpan = clock.querySelector('.minutes');
  var secondsSpan = clock.querySelector('.seconds');

  function updateClock() {
    var t = getTimeRemaining(endtime);

    daysSpan.getElementsByTagName('span')[0].innerHTML = t.days;
    calculatePercentage(daysSpan, t.days, 365);

    hoursSpan.getElementsByTagName('span')[0].innerHTML = ('0' + t.hours).slice(-2);
    calculatePercentage(hoursSpan, t.hours, 24);

    minutesSpan.getElementsByTagName('span')[0].innerHTML = ('0' + t.minutes).slice(-2);
    calculatePercentage(minutesSpan, t.minutes, 60);

    secondsSpan.getElementsByTagName('span')[0].innerHTML = ('0' + t.seconds).slice(-2);
    calculatePercentage(secondsSpan, t.seconds, 60);

    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }

  updateClock();
  var timeinterval = setInterval(updateClock, 1000);
}

module.exports = initializeClock;